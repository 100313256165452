import AppLanding from "modules/app-landing";

export default function Home() {
  return (
    <div>
      <section className="bg-gradient-to-b from-gray-100 to-white">
        <AppLanding />
      </section>
    </div>
  );
}

import config from "config";

const cards = [
  {
    link: "/recipes",
    image: "/undraw_success_factors_fay0.svg",
    title: "Start A New Project",
    examples: [
      "Predictive Analytics",
      "Driver Analysis",
      "Clustering",
      "Data Exploration",
      "Forecasting",
      "And more",
    ],
  },
  {
    link: "/existing-analysis",
    image: "/undraw_animating_1rgh.svg",
    title: "See Existing Analysis and Data",
    examples: [
      "Deep-Dives",
      "Insights",
      "Exports",
      "Predictions",
      "Data Updates",
      "Sharing",
      "And more",
    ],
  },
  {
    link: "/data-upload",
    image: "/undraw_Add_files_re_v09g.svg",
    title: `Upload Data into ${config.SITE_NAME}`,
    examples: ["csv", "excel", "parquet", "local-files", "cloud object stores"],
  },
  // {
  //   link: "/transform-data",
  //   image: "/undraw_File_bundle_xl7g.svg",
  //   title: "Create New Data",
  //   examples: [
  //     "cleanup",
  //     "new columns",
  //     "aggregates",
  //     "filters",
  //     "time-series",
  //     "time-window stats",
  //     "and more",
  //   ],
  // },
];

export default cards;

import Link from "next/link";
import Image from "next/image";

import cards from "./cards";

const AppLanding = () => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
      <div className="pt-12 pb-12">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-4 mb-8">
          <h1 className="h1">What do you want to do today?</h1>
        </div>
        <div className="mx-auto mt-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {cards.map((c) => (
              <div key={c.link} className="m-2 pt-2 pb-4 px-3 shadow">
                <Link href={c.link}>
                  <a>
                    <Image
                      src={c.image}
                      alt={c.title}
                      width={500}
                      height={250}
                    />
                    <p className="text-2xl font-bold text-center pt-4 mt-4">
                      {c.title}
                    </p>
                    {c.examples && (
                      <div className="flex flex-wrap justify-center">
                        {c.examples.map((x) => (
                          <div
                            key={x}
                            className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-gray-700 bg-gray-300 border border-gray-300 "
                          >
                            <div className="text-xs font-normal leading-none max-w-full flex-initial">
                              {x}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLanding;
